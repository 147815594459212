import React from 'react';
import Layout from '../components/layout';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import Footer from '../components/footer';

const BlogPost = ({ pageContext }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost {
          edges {
            node {
              date(formatString: "MMMM Do YYYY")
              acf {
                featured_image {
                  source_url
                }
              }
            }
          }
        }
      }
    `}
    render={(props) => (
      <Layout>
        <div className='blog-list-body blog-post-content'>
          <section className='hero is-fullheight'>
            <div className='container column is-6'>
              <div className='blog-post-btn column is-4-desktop is-offset-4-desktop is-12-mobile'>
                <Link to='/blog'>Back To Blog</Link>
              </div>
              <h1 dangerouslySetInnerHTML={{ __html: pageContext.title }} />
              <div
                dangerouslySetInnerHTML={{ __html: pageContext.content }}
                className='blog-post-copy'
              />
              <div className='blog-post-btn column is-4-desktop is-offset-4-desktop is-12-mobile'>
                <Link to='/blog'>Back To Blog</Link>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Layout>
    )}
  />
);

export default BlogPost;
